import React, { Fragment } from 'react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { Cards } from '@amzn/awsui-components-react';
import * as Logger from '../../util/Logger';
import { messages } from '../../intl';
import { sizing, typographyStyles } from '../../util/Styles';
import { decryptCustomerId } from '@amzn/amazon-id';
export const headerStyle = css `
    margin-bottom: ${sizing.small};
`;
export const dateResultsStyle = css `
    margin: ${sizing.small};
`;
const ReturnsSLACalculator = ({ marketplace, orderId, commId, sessionId, traceId, intl, }) => (React.createElement(Cards, { header: React.createElement(Fragment, null,
        React.createElement("a", { className: cx(headerStyle, typographyStyles.fontSize3), href: "https://drive-render.corp.amazon.com/view/CAP%20India%20tools/EU5.html", onClick: () => Logger.dataWarehouseEvent({
                namespace: 'GetQueryCallback',
                key: 'SLADateCalculatorClicked',
                value: true,
                marketplace,
                orderId,
                commId: decryptCustomerId(commId),
                sessionId,
                traceId,
            }), rel: "noopener noreferrer" // See https://mathiasbynens.github.io/rel-noopener
            , target: "_blank" },
            React.createElement("div", { className: dateResultsStyle },
                React.createElement("div", null,
                    React.createElement("strong", null, intl.formatMessage(messages.SLA_DATE_CALCULATOR)))))) }));
export default injectIntl(ReturnsSLACalculator);
