import React from 'react';
import { css } from 'emotion';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import uuid from 'uuid';
import { decryptCustomerId } from '@amzn/amazon-id';
import * as Logger from '../../util/Logger';
import CustomerHeader from '../CustomerHeader';
import { sizing } from '../../util/Styles';
import ConcessionsActivity from '../ConcessionsActivity';
import OrderSummary from '../OrderSummary';
import OrdersWithCAPContact from '../OrdersWithCAPContact';
import ReturnsSLACalculator from '../ReturnsSLACalculator';
import { marketplaceToCurrencyCode } from '../../util/Localization';
import { redactFieldPathsFromData } from '../../util/DataRedactor';
import config from '../../config';
import { regionIdFromMarketplace, obfuscatedIdFromMarketplace } from '../../config/marketplaces';
import CAPWorkflow from '../CAPWorkflow';

const uuidv1 = require('uuid/v1');

const OPERATION_NAME = 'Hello:graphQLQuery';
const RENDERED_DATA_EVENT = 'Hello:renderedDataOnPage';
const GRAPHQL_REQUEST_DATA = 'Hello:graphQLRequestData';
const GRAPHQL_RESPONSE_DATA = 'Hello:graphQLResponseData';

const bodyContentStyles = css`
    padding: ${sizing.medium};
`;

const bodyLayoutStyles = css`
    display: flex;
    flex-direction: row;
`;

const customerHeaderLayoutStyles = css`
    padding-bottom: ${sizing.small};
`;

const orderSummaryLayoutStyles = css`
    margin-right: 20px;
    max-width: 400px;
`;

const warningStyles = css`
    color: red;
    font-weight: bold;
`;

const getDeobfuscatedInputs = (yellowData) => {
    const deobfuscatedInputs = Object.assign({}, yellowData);
    if (deobfuscatedInputs.customerId !== undefined) deobfuscatedInputs.customerId = decryptCustomerId(deobfuscatedInputs.customerId);
    if (deobfuscatedInputs.commId !== undefined) deobfuscatedInputs.commId = decryptCustomerId(deobfuscatedInputs.commId);
    if (deobfuscatedInputs.languageCode !== undefined) deobfuscatedInputs.languageCode = deobfuscatedInputs.languageCode.replace('-', '_');
    return deobfuscatedInputs;
};

export const logValidatedInputsToDataWarehouse = (validatedInputs, sessionId) => {
    const { signature, marketplace, ...yellowData } = validatedInputs;
    const dwMessageBody = {
        ...getDeobfuscatedInputs(yellowData),
        marketplaceId: decryptCustomerId(obfuscatedIdFromMarketplace(marketplace)),
        eventUuid: uuid.v4(),
        regionId: regionIdFromMarketplace(marketplace),
        sessionId,
    };
    Logger.dataWarehouse('Hello:logValidatedInputsToDataWarehouse:validatedInputs', dwMessageBody, {
        provider: 'capwebsite',
        subject: 'inputparameters',
        dataset: config.REALM,
        unitPeriod: 'hour',
        schema: {
            version: {
                major: 8,
                minor: 0,
            },
            string: 'elements: [{name: "event_uuid", type:{base:string, max_length:36}},{name: "event_datetime_utc", type:{base:timestamp, timestamp_mask:"yyyy-MM-dd\'T\'HH:mm:ss"}},{name: "account_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "comm_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "customer_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "language_code", type:{base:string, max_length:5}},{name: "marketplace_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "order_id", type:{base:string, max_length:19}},{name: "customer_purchase_id", type:{base:string, max_length:19}},{name: "source", type:{base:string, max_length:100}},{name: "session_id", type:{base:string, max_length:36}},{name: "event_day", type:{base:timestamp, timestamp_mask:"yyyy-MM-dd"}},{name: "region_id", type:{base:int, min_value:-32768, max_value:32767}}],',
            elements: [
                '$.message.body.eventUuid',
                '$.timestampAndes',
                '$.message.body.accountId',
                '$.message.body.commId',
                '$.message.body.customerId',
                '$.message.body.languageCode',
                '$.message.body.marketplaceId',
                '$.message.body.orderId',
                '$.message.body.purchaseId',
                '$.message.body.source',
                '$.message.body.sessionId',
                '$.eventDay',
                '$.message.body.regionId',
            ],
        },
    });
};

export const getCAPWorkflowComponent = (inputs, sessionId) => {
    if (['IN_DEVO', 'IN_PROD'].includes(inputs.marketplace)) {
        return null;
    }
    return <CAPWorkflow
        initialContext={{ signatureValidation: inputs }}
        sessionId={sessionId}
    />;
};

export const getQuery = (requestUUID, commId, accountId) => {
    const now = new Date(Date.now());
    const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));
    const oneYearAgoMidnightIso = new Date(oneYearAgo.setHours(0, 0, 0, 0)).toISOString();
    const query = `query CustomerCapData($customerId: String!, $marketplace: Marketplace!, $orderId: String!) {
                    customer(input: {customerId: $customerId, marketplace: $marketplace}) {
                        name
                        accountCreationDate
                        accountStatus
                        idVerificationStatus
                        summary {
                            concessions { count, value }
                            dnr { count, value }
                            mdr { count, value }
                            orders { count, value }
                            returnlessRefunds { count, value }
                            returns { count, value }
                        }
                        order(input: {orderId: $orderId}) {
                            contacts {
                                createdDate
                                reason
                            }
                        }
                        ordersWithCapContact(sinceDate: "${oneYearAgoMidnightIso}") {
                            id
                            contacts {
                                createdDate
                                reason
                            }
                            hasAssociatedConcession
                        }
                    }
                }`;
    Logger.info(GRAPHQL_REQUEST_DATA, {
        query,
        requestUUID,
        commId,
        accountId,
    });
    return gql(query);
};

export const getIsKYCEnabledInMarketplace = marketplace =>
    ['US', 'CA', 'DE', 'ES_DEVO', 'ES_PROD', 'FR', 'IT_DEVO', 'IT_PROD', 'UK'].includes(marketplace);

export const getQueryCallback = (inputs, startTime, requestUUID, sessionId) => ({ data, loading, error }) => {
    if (loading) return <p>Loading…</p>;

    if (error) {
        Logger.enqueueFailedMessage(OPERATION_NAME, startTime, { error, requestUUID });
    }

    if (!data) {
        return <p>Error when running GraphQL query!</p>;
    }

    logValidatedInputsToDataWarehouse(inputs, sessionId);

    const redactedData = redactFieldPathsFromData(data, ['customer.name', '__schema'], requestUUID);
    const logSafeData = {
        ...redactedData,
        requestUUID,
        customer: {
            ...redactedData.customer,
            customerId: inputs.customerId,
        },
    };
    Logger.info(GRAPHQL_RESPONSE_DATA, logSafeData);

    Logger.enqueueSucceededMessage(OPERATION_NAME, startTime);
    Logger.enqueueDurationFromAppStartMessage(RENDERED_DATA_EVENT);
    const customer = data.customer || {};
    const order = customer.order || {};
    const isKYCEnabledMarketplace = getIsKYCEnabledInMarketplace(inputs.marketplace);
    return (
        <div className={bodyContentStyles}>
            {!inputs.commId && <div className={warningStyles}>You are accessing the CAP Website outside of an active customer contact. Please be advised that policies will be set in place in the near-term to restrict CAP Website access to ensure customer data privacy.</div>}
            <div className={customerHeaderLayoutStyles}>
                <CustomerHeader customerName={customer.name} customerAccountCreationDate={customer.accountCreationDate} customerIDVerificationStatus={customer.idVerificationStatus} isKYCEnabledMarketplace={isKYCEnabledMarketplace} customerARIAccountStatus={customer.accountStatus} />
            </div>
            <div className={bodyLayoutStyles}>
                <div className={orderSummaryLayoutStyles}>
                    <OrderSummary
                        orderId={inputs.orderId}
                        relatedContacts={order.contacts}
                    />
                </div>
                <div>
                    {getCAPWorkflowComponent(inputs, sessionId)}
                    <ReturnsSLACalculator
                        marketplace={inputs.marketplace}
                        orderId={inputs.orderId}
                        commId={inputs.commId}
                        sessionId={sessionId}
                        traceId={inputs.traceId}
                    />
                    <ConcessionsActivity
                        currency={marketplaceToCurrencyCode[inputs.marketplace]}
                        customerSummary={customer.summary}
                    />
                    <OrdersWithCAPContact orders={customer.ordersWithCapContact} />
                </div>
            </div>
        </div>
    );
};

export default function Hello({ inputs, sessionId }) {
    // @TODO wrap the instrumentation into an Apollo link
    const startTime = Date.now();
    const requestUUID = uuidv1();

    const { commId, accountId } = inputs;

    Logger.enqueueStartedMessage(OPERATION_NAME);
    return (
        <Query
            query={getQuery(requestUUID, commId, accountId)}
            variables={inputs}
        >
            {getQueryCallback(inputs, startTime, requestUUID, sessionId)}
        </Query>
    );
}
